<template>
  <div class="content">
    <div class="wrapper">
      <div class="about">
        <div class="about__container">
          <header class="header">
            <div class="header__elipse header__elipse-01"></div>
            <div class="header__elipse header__elipse-02"></div>
            <div ref="headerMenu" class="header__menu menu-header">
              <div class="menu-header__logo">
                <img src="@/assets/img/logo-bibig.svg" alt="Логотип BIBIG" />
              </div>
              <ul
                v-if="visible"
                ref="headerMenuMobile"
                class="menu-header__inner"
              >
                <li @click="scrollToAnchorPoint('about')">
                  {{ $t('menu-header.about') }}
                </li>
                <li @click="scrollToAnchorPoint('map')">
                  {{ $t('menu-header.map') }}
                </li>
                <!-- <li @click="scrollToAnchorPoint('development')">{{ $t('menu-header.development') }}</li> -->
                <li @click="scrollToAnchorPoint('reviews')">
                  {{ $t('menu-header.reviews') }}
                </li>
              </ul>
              <div v-if="!visible" mode="out-in" class="menu-header__burger">
                <sidebar-component>
                  <ul ref="headerMenuMobile" class="menu-header__inner">
                    <li @click="scrollToAnchorPoint('about')">
                      {{ $t('menu-header.about') }}
                    </li>
                    <li @click="scrollToAnchorPoint('map')">
                      {{ $t('menu-header.map') }}
                    </li>
                    <!-- <li @click="scrollToAnchorPoint('development')">{{ $t('menu-header.development') }}</li> -->
                    <li @click="scrollToAnchorPoint('reviews')">
                      {{ $t('menu-header.reviews') }}
                    </li>
                    <li @click="scrollToAnchorPoint('feed')">
                      {{ $t('menu-header.feed') }}
                    </li>
                  </ul>
                </sidebar-component>
                <burger-component></burger-component>
              </div>
            </div>
            <div ref="aboutInner" class="about__inner">
              <h1 class="about__title">
                <span>Bibig </span>- {{ $t('title') }}
              </h1>
              <p class="about__subtitle">{{ $t('subtitle') }}</p>
              <p class="about__number-us">
                {{ $t('join') }}
                <span data-count="">0</span>
              </p>
              <ul class="about__list">
                <li class="about__item">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ru.bokus.bibig"
                    ><img src="@/assets/img/googleplay.svg" alt="Googleplay"
                  /></a>
                </li>
                <li class="about__item">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/ru/app/bibig/id1613717337"
                    ><img src="@/assets/img/appstore.svg" alt="Appstore"
                  /></a>
                </li>
                <li class="about__item">
                  <a
                    target="_blank"
                    href="https://www.rustore.ru/catalog/app/ru.bokus.bibig"
                    ><img
                      src="@/assets/img/RuStore_logo_dark.svg"
                      alt="RuStore"
                  /></a>
                </li>
              </ul>
            </div>
          </header>
          <section ref="about" class="about__wrapper">
            <div ref="aboutRow" class="about__row">
              <div ref="aboutImg" class="about__left">
                <div class="about__img about__img-main">
                  <img
                    v-bind:src="
                      require(`@/assets/img/langImg/header_${isLocale}.png`)
                    "
                    alt="header"
                  />
                </div>
                <div class="about__img about__img-01">
                  <img
                    v-bind:src="
                      require(`@/assets/img/langImg/search_${isLocale}.png`)
                    "
                    alt="search"
                  />
                </div>
                <div class="about__img about__img-02">
                  <img
                    v-bind:src="
                      require(`@/assets/img/langImg/profile_${isLocale}.png`)
                    "
                    alt="profile"
                  />
                </div>
              </div>
              <div class="about__right">
                <div class="about__decription decription-about">
                  <div class="decription-about__inner">
                    <h2 class="decription-about__title">
                      {{ $t('decription-about.title.card-1') }}
                    </h2>
                    <p class="decription-about__text">
                      {{ $t('decription-about.decription.card-1') }}
                    </p>
                  </div>
                </div>
                <div class="about__decription decription-about">
                  <div class="decription-about__inner">
                    <h2 class="decription-about__title">
                      {{ $t('decription-about.title.card-2') }}
                    </h2>
                    <p class="decription-about__text">
                      {{ $t('decription-about.decription.card-2') }}
                    </p>
                  </div>
                </div>
                <div class="about__decription decription-about">
                  <div class="decription-about__inner">
                    <h2 class="decription-about__title">
                      {{ $t('decription-about.title.card-3') }}
                    </h2>
                    <p class="decription-about__text">
                      {{ $t('decription-about.decription.card-3') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <main class="page">
        <section ref="map" class="map">
          <map-component />
        </section>
        <!-- <section ref="development" class="development">
          <development-component />
        </section> -->
        <section ref="reviews" class="reviews">
          <reviews-component />
        </section>
        <section ref="feed" class="feed">
          <feed-form-component />
        </section>
      </main>
      <footer class="footer">
        <div class="footer__container">
          <nav class="footer__menu menu-footer">
            <ul class="menu-footer__list">
              <li
                class="menu-footer__link"
                @click="scrollToAnchorPoint('about')"
              >
                {{ $t('menu-header.about') }}
              </li>
              <li class="menu-footer__link" @click="scrollToAnchorPoint('map')">
                {{ $t('menu-header.map') }}
              </li>
              <!-- <li class="menu-footer__link" @click="scrollToAnchorPoint('development')">{{ $t('menu-header.development')}}</li> -->
              <li
                class="menu-footer__link"
                @click="scrollToAnchorPoint('reviews')"
              >
                {{ $t('menu-header.reviews') }}
              </li>
              <li
                class="menu-footer__link"
                @click="scrollToAnchorPoint('feed')"
              >
                {{ $t('menu-header.feed') }}
              </li>
            </ul>
          </nav>
          <ul class="footer__list">
            <li class="footer__item">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=ru.bokus.bibig"
                ><img src="@/assets/img/googleplay.svg" alt="Googleplay"
              /></a>
            </li>
            <li class="footer__item">
              <a
                target="_blank"
                href="https://apps.apple.com/ru/app/bibig/id1613717337"
                ><img src="@/assets/img/appstore.svg" alt="Appstore"
              /></a>
            </li>
            <li class="footer__item">
              <a
                target="_blank"
                href="https://www.rustore.ru/catalog/app/ru.bokus.bibig"
                ><img src="@/assets/img/RuStore_logo_dark.svg" alt="RuStore"
              /></a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import SidebarComponent from '@/components/menu/SidebarComponent.vue';
import BurgerComponent from '@/components/menu/BurgerComponent.vue';
import MapComponent from '@/components/MapComponent.vue';
// import DevelopmentComponent from "@/components/DevelopmentComponent.vue";
import ReviewsComponent from '@/components/ReviewsComponent.vue';
import FeedFormComponent from '@/components/FeedFormComponent.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'App',
  components: {
    MapComponent,
    // DevelopmentComponent,
    ReviewsComponent,
    FeedFormComponent,
    BurgerComponent,
    SidebarComponent,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' });
    const switchLand = (lang) => {
      locale.value = lang;
      localStorage.setItem('lang', locale.value);
      document.querySelector('.sort-lang__sort-label span').innerText =
        locale.value;
      store.dispatch('setLanguage', lang);
    };
    const store = useStore();
    // const activeLanguageFromBrowser = window.location.pathname.split('/')[1]; если будут папки /en,/ru
    store.dispatch('setLanguage', locale.value);
    return { store, t, locale, switchLand };
  },
  data() {
    return {
      width: 0,
      height: 0,
      visible: true,
      phone: 0,
      langs: ['ru'],
    };
  },
  mounted() {
    //Плавный скролл-----------------//
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    if (ScrollTrigger.isTouch !== 1) {
      ScrollSmoother.create({
        wrapper: '#id',
        content: '.content',
        smooth: 1.5,
        effects: true,
      });
    }

    //----------------------------------//

    //Движение телефона-----------------//
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    const elementHeader = this.$refs.headerMenu;
    const headerMenu = elementHeader.offsetHeight;
    this.heightMenu = headerMenu;

    const elementaboutImg = this.$refs.aboutImg;
    let aboutImg;
    let xImg;
    let coefX;
    let center;

    const elementAbout = this.$refs.aboutInner;
    const style = getComputedStyle(elementAbout);

    const aboutInner = elementAbout.offsetHeight + parseInt(style.marginBottom);
    this.heightAbout = aboutInner;

    const imagesPhone = document.querySelector('.about__img-main');
    const imagesRow = this.$refs.aboutRow;

    if (this.width <= 991) {
      this.visible = false;
    } else {
      this.visible = true;
    }

    if (this.width <= 1366 && this.width > 991) {
      coefX =
        (this.width / imagesPhone.offsetWidth) *
        ((imagesPhone.offsetWidth * 15) / 100 / 100);
      xImg = 0;
      aboutImg = 0;
      this.phone =
        this.height -
        this.heightAbout +
        (this.height / 2 - (imagesPhone.offsetHeight / 2) * coefX);
      center =
        imagesRow.offsetWidth / 2 -
        elementaboutImg.offsetLeft -
        imagesPhone.offsetWidth / 2;
    } else if (this.width <= 991 && this.width > 767) {
      coefX =
        (this.width / imagesPhone.offsetWidth) *
        ((imagesPhone.offsetWidth * 25) / 100 / 100);
      xImg = 0;
      aboutImg = 0;
      this.phone =
        this.height -
        this.heightAbout +
        (this.height / 2 - (imagesPhone.offsetHeight / 2) * coefX);
      center =
        imagesRow.offsetWidth / 2 -
        elementaboutImg.offsetLeft -
        imagesPhone.offsetWidth / 2;
    } else if (this.width <= 767) {
      coefX = 1.7;
      xImg = 0;
      aboutImg = 0;
      this.phone =
        this.height -
        this.heightAbout +
        ((imagesPhone.offsetWidth * 35) / 100 -
          ((elementaboutImg.offsetHeight * 20) / 100) * coefX);
      center = 0;
    } else {
      coefX =
        (this.width / imagesPhone.offsetWidth) *
        ((imagesPhone.offsetWidth * 8) / 100 / 100);
      xImg = 0;
      aboutImg = 0;
      this.phone =
        this.height -
        this.heightAbout -
        this.heightMenu +
        (this.height / 2 - (imagesPhone.offsetHeight / 2) * coefX);
      center =
        imagesRow.offsetWidth / 2 -
        elementaboutImg.offsetLeft -
        imagesPhone.offsetWidth / 2;
    }

    // console.log("M " + this.phone)
    // console.log("Mheight " + this.height)
    // console.log(elementaboutImg.offsetHeight * 20 / 100)
    // console.log(imagesPhone.offsetHeight)
    // console.log(this.height * 20 / 100)
    // console.log("MheightMenu " + this.heightMenu)
    // console.log("MheightAbout " + this.heightAbout)

    gsap.fromTo(
      '.about__img-main img',
      {
        scale: coefX,
        y: -this.phone,
        x: center,
        duration: 2,
      },
      {
        scale: 1,
        left: aboutImg,
        y: 0,
        x: xImg,
        scrollTrigger: {
          duration: 2,
          trigger: '.about__img-main img',
          start: () => 'top +' + imagesPhone.offsetHeight / 2,
          end: () => '+=' + imagesPhone.offsetHeight,
          scrub: true,
          ease: 'Power3.easeOut',
        },
      }
    );

    //----------------------------------//
    if (this.width > 991) {
      //Липкая шапка---------------------//
      ScrollTrigger.create({
        trigger: '.menu-header',
        start: 'top top',
        endTrigger: 'footer',
        end: 'bottom+=300%',
        pin: true,
        pinType: 'fixed',
        markers: false,
        pinReparent: true,
        pinSpacing: false,
      });
      //----------------------------------//
    }

    //Движение колонок-----------------//

    let elementTriggerRight;
    let elementTriggeleft;

    if (this.width <= 991) {
      elementTriggerRight = '.about__row';
      elementTriggeleft = '.about__row';
    } else {
      elementTriggerRight = '.about__right';
      elementTriggeleft = '.about__left';
    }

    gsap.set('.about__img', {
      zIndex: (i, target, targets) => targets.length - i,
    });
    const images = gsap.utils.toArray('.about__img');
    images.forEach((image, i) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: elementTriggeleft,
          start: () => 'top -' + window.innerHeight * (i / 2),
          end: () => '+=' + window.innerHeight,
          scrub: true,
          toggleActions: 'play none reverse none',
          invalidateOnRefresh: true,
        },
      });
      if (i == images.length - 1) {
        tl.to(image, { duration: 0.33, opacity: 1 }).to(
          image,
          { duration: 0.33, opacity: 1 },
          '-=0.3'
        );
      } else if (i == 0) {
        tl.to(image, { duration: 0.33, opacity: 0 }, '+=0.1');
      } else {
        tl.to(image, { duration: 0.33, opacity: 1 }).to(image, {
          duration: 0.33,
          opacity: 0,
        });
      }
    });

    gsap.set('.decription-about', {
      zIndex: (i, target, targets) => targets.length - i,
    });
    var texts = gsap.utils.toArray('.decription-about');
    texts.forEach((text, i) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: elementTriggerRight,
          start: () => 'top -' + window.innerHeight * (i / 2),
          end: () => '+=' + window.innerHeight,
          scrub: true,
          toggleActions: 'play none reverse none',
          invalidateOnRefresh: true,
        },
      });
      if (this.width <= 767.98) {
        if (i == texts.length - 1) {
          tl.to(text, { duration: 0.33, opacity: 1, y: '0%' }, 0.66).to(text, {
            duration: 1,
            opacity: 1,
            y: '0%',
          });
        } else if (i == 0) {
          tl.to(text, { duration: 0.33, opacity: 1, y: '0%' }, 0.66).to(text, {
            duration: 1,
            opacity: 0,
            y: '-10%',
          });
        } else {
          tl.to(text, { duration: 0.33, opacity: 1, y: '0%' }, 0.66).to(text, {
            duration: 1,
            opacity: 0,
            y: '-10%',
          });
        }
      } else {
        if (i != texts.length - 1) {
          tl.to(text, { duration: 0.33, y: '0%' }).to(
            text,
            { duration: 1, y: '-90%' },
            0.66
          );
        } else {
          tl.to(text, { duration: 0.33, y: '0%' }).to(
            text,
            { duration: 0.33, y: '0%' },
            0.66
          );
        }
      }
    });

    ScrollTrigger.create({
      trigger: '.about__row',
      scrub: true,
      markers: false,
      pin: true,
      start: () => 'top top',
      end: () => '+=' + ((images.length + 0.5) * window.innerHeight) / 2,
      invalidateOnRefresh: true,
    });

    //----------------------------------//

    //Бегущие цифры - Счетчик чисел----//
    const number = document.querySelector('.about__number-us span');
    number.dataset.count = (
      (Date.now() - new Date(1683102562414)) / 278000 +
      75836
    ).toFixed();
    function updateCount() {
      const target = +number.getAttribute('data-count');
      const count = +number.innerHTML;

      const inc = Math.floor((target - count) / 25);

      if (count < target && inc > 0) {
        number.innerHTML = count + inc;
        setTimeout(updateCount, 1);
      } else {
        number.innerHTML = target;
      }
    }
    updateCount();
    //----------------------------------//
  },
  computed: {
    isBurgerActive() {
      return this.$store.getters.NAV;
    },
    isLocale: function () {
      return this.$store.getters.activeLanguage;
    },
    langActive: function () {
      return this.langs.filter((lang) => lang !== this.isLocale);
    },
  },
  watch: {
    isBurgerActive: {
      immediate: true,
      handler(isBurgerActive) {
        document.body.classList.toggle('active', isBurgerActive);
      },
    },
  },
  methods: {
    scrollToAnchorPoint(sectionRef) {
      gsap.registerPlugin(ScrollToPlugin);
      var element = this.$refs[sectionRef];
      var top = element.offsetTop;
      if (this.width < 991) {
        this.$store.commit('toggleNav');
      }
      if (sectionRef == 'about') {
        gsap.to(window, {
          duration: 1,
          scrollTo: { y: top + 300 },
          ease: 'power2.inOut',
        });
      } else {
        gsap.to(window, {
          duration: 2,
          scrollTo: { y: top },
          ease: 'power2.inOut',
        });
      }
    },
    myEventHandler(e) {
      console.log(e);
      console.log(e.currentTarget);
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    openMenu(event) {
      event.target.closest('.sort-lang__sort').classList.toggle('active');
    },
  },
  created() {
    window.addEventListener('resize', this.myEventHandler);
  },
  unmounted() {
    window.removeEventListener('resize', this.myEventHandler);
  },
};
</script>
<style lang="scss">
@import 'src/assets/style';

body.active {
  overflow: hidden;
}

.header {
  height: 100vh;
  position: relative;

  &__elipse-01 {
    position: absolute;
    top: percent(363, 1154);
    right: percent(1528, 1902);
    z-index: -1;
    width: 802px;
    height: 802px;
    border-radius: 802px;
    background: rgba(229, 219, 251, 0.6);
    filter: blur(98.5px);
  }

  &__elipse-02 {
    position: absolute;
    top: percent(677, 1154);
    left: percent(1269, 1902);
    z-index: -1;
    width: 694px;
    height: 694px;
    border-radius: 694px;
    background: rgba(254, 226, 236, 0.6);
    filter: blur(163px);
  }
}

.about {
  &__wrapper {
    @media screen and (max-width: $mobile) {
      padding: 0 20px !important;
    }
  }

  &__title {
    text-align: center;
    max-width: 959px;
    font-weight: 700;
    line-height: 120%;
    margin: 0 auto em(30, 70) auto;

    @media screen and (max-width: $looptop) {
      max-width: 688px;
    }

    @media screen and (max-width: $tablet) {
      margin: 0 auto em(30, 52) auto;
    }

    @media screen and (max-width: $mobileSmall) {
      max-width: 330px;
      margin: 0 auto em(20, 32) auto;
    }
  }

  &__subtitle {
    margin: 0 auto em(15, 24) auto;
    text-align: center;
    color: $mainColor;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: 617px;

    @media screen and (max-width: $looptop) {
      max-width: 600px;
      margin: 0 auto em(8, 20) auto;
    }

    @media screen and (max-width: $tablet) {
      font-size: 20px;
      margin: 0 auto em(8, 20) auto;
    }

    @media screen and (max-width: $mobileSmall) {
      font-size: 16px;
      margin: 0 auto em(8, 16) auto;
    }
  }

  &__number-us {
    font-weight: 400;
    line-height: normal;
    margin: 0 auto em(25, 24) auto;
    text-align: center;

    @media screen and (max-width: $looptop) {
      margin: 0 auto em(33, 24) auto;
    }

    @media screen and (max-width: $tablet) {
      font-size: 20px;
      margin: 0 auto em(33, 20) auto;
    }

    @media screen and (max-width: $mobileSmall) {
      font-size: 16px;
      margin: 0 auto em(18, 16) auto;
    }

    span {
      color: #4d2186;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media screen and (max-width: $looptop) {
        font-size: 24px;
      }

      @media screen and (max-width: $mobileSmall) {
        font-size: 20px;
        margin: 0 auto em(18, 20) auto;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    column-gap: rem(10);
    justify-content: center;
    max-height: 47px;
    height: 47px;
    flex-wrap: wrap;

    @media screen and (max-width: $looptop) {
      gap: rem(6);
    }
  }

  &__inner {
    margin-bottom: rem(28);

    @media screen and (max-width: $looptop) {
      margin-bottom: rem(18);
    }

    @media screen and (max-width: $tablet) {
      padding: rem(134) rem(20) 0 rem(20) !important;
    }

    @media screen and (max-width: $mobile) {
      padding: rem(70) rem(20) 0 rem(20) !important;
    }
  }

  &__item a {
    display: inline-block;

    img {
      max-height: 59px;

      @media screen and (max-width: $looptop) {
        max-height: 40px;
      }
    }
  }

  &__container {
    position: relative;

    @media screen and (max-width: $mobile) {
      padding: 0 !important;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
      justify-content: unset;
      row-gap: rem(40);
    }

    @media screen and (max-height: 667px) {
      row-gap: rem(20);
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: calc($sizeBlock * 35);
    width: calc($sizeBlock * 20);
    max-height: 842.748px;
    max-width: 390px;

    @media screen and (max-width: $looptop) {
      height: calc($sizeBlock * 26);
    }

    @media screen and (max-width: $mobile) {
      top: 20%;
      left: 50%;
      transform: translate(-50%, -20%);
      min-height: 470.517px;
    }

    @media screen and (max-width: $mobile) and (min-height: 678px) {
      min-height: 420.517px;
    }

    @media screen and (max-width: $mobile) and (max-height: 677px) {
      min-height: 270.517px;
    }

    &:not(:first-child) {
      opacity: 0;
    }

    img {
      position: absolute;
      z-index: 2;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &__img-main img {
    position: absolute;
    z-index: 4;
    transition: 0.2s;
    // transform: translateY(-20%);
    will-change: transform;
  }

  &__left {
    width: calc($sizeBlock * 15);
    max-width: 529.007px;
    height: 100vh;
    z-index: 4;

    @media screen and (max-width: $mobile) {
      height: 60vh;
      width: calc($sizeBlock * 30);
    }
  }

  &__right {
    position: relative;
    width: 100%;
    max-width: 689px;
    height: 100vh;

    @media screen and (max-width: $looptop) {
      max-width: 524px;
    }

    @media screen and (max-width: $tablet) {
      max-width: 351px;
    }

    @media screen and (max-width: $mobile) {
      height: 30vh;
    }
  }
}

.decription-about {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform: translateY(100%);

  &:first-child {
    transform: translateY(0%);

    @media screen and (max-width: $mobile) {
      opacity: 1;
    }
  }

  @media screen and (max-width: $mobile) {
    transform: translateY(70%);
    opacity: 0;
  }

  &__inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: $mobile) {
      top: 10%;
      transform: translateY(-10%);
    }
  }

  &__title {
    margin-bottom: em(60, 50);

    @media screen and (max-width: $looptop) {
      margin-bottom: em(60, 38);
    }

    @media screen and (max-width: $tablet) {
      margin-bottom: em(30, 32);
    }

    @media screen and (max-width: $mobileSmall) {
      margin-bottom: em(15, 28);
    }
  }

  &__text {
    @media screen and (max-width: $tablet) {
      font-size: rem(16);
    }
  }
}

.menu-header {
  display: flex;
  align-items: center;
  z-index: 999;
  padding: rem(31) 0 rem(43) 0;

  @media screen and (max-width: $tablet) {
    align-items: flex-start;
  }

  @media screen and (max-width: $mobile) {
    box-sizing: border-box;
  }

  &__inner {
    display: flex;
    align-items: center;
    padding: 19px 20px 20px 20px;
    align-items: flex-start;
    column-gap: 50px;
    border-radius: 10px;
    border-radius: 10px;
    background: rgba(246, 245, 249, 0.92);
    backdrop-filter: blur(7.5px);
    cursor: pointer;
    margin: 0 auto;
    transform: translateX(-18px);
  }

  &__inner li {
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    transition: all 0.5s ease 0s;

    @media (any-hover: hover) {
      &:hover {
        color: #4d2186;
      }
    }
  }

  &__logo {
    position: relative;
    max-width: 123px;
    max-height: 66px;
    min-width: 86px;
    width: calc($sizeBlock * 4);
    height: calc($sizeBlock * 4);
    border-radius: 67px;
    background: rgba(255, 255, 255, 0.92);
    backdrop-filter: blur(7.5px);

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      padding: 6px 12px;
    }
  }

  &__burger {
    display: flex;
    column-gap: rem(20);
    align-items: center;
    padding-right: 30px;

    @media screen and (max-width: $tablet) {
      align-items: flex-start;
    }

    @media screen and (max-width: $mobile) {
      padding-right: 0;
    }
  }

  @media screen and (max-width: $tablet) {
    justify-content: space-between;
    // padding: rem(20) 0 rem(40) 0;
    max-height: 134px;
    height: auto;
    position: fixed;
    width: 100%;
    max-width: 106rem;
    box-sizing: content-box;
    margin: 0 auto;
    // padding: 0 0.9375rem;

    &__inner {
      flex-direction: column;
      row-gap: rem(14);
      background: transparent;
    }

    &__inner {
      transform: translateX(0);
      padding: 0;
    }

    &__logo {
      z-index: 1000;
      width: 65px;
      height: 40px;
      border-radius: 67px;
      background: rgba(255, 255, 255, 0.92);
      backdrop-filter: blur(7.5px);
      padding: 6px 12px;
    }

    &__inner li {
      background: #f2f1f7;
      width: 100%;
      height: 100%;
      padding: 12px;
      border-radius: 10px;
      font-size: rem(18);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  @media screen and (max-width: $mobile) {
    padding: rem(6) 0 rem(25) 0;
    height: 45px;
  }
}

.footer {
  background: #4d2186;
  padding: rem(40) 0;

  @media screen and (max-width: $tablet) {
    padding: rem(37) 0;
  }

  @media screen and (max-width: $mobileSmall) {
    padding: rem(30) 0;
  }

  &__list {
    display: flex;
    gap: rem(6);
    flex-wrap: wrap;

    @media screen and (max-width: $tablet) {
      justify-content: center;
    }
  }

  &__item img {
    max-height: 46px;

    @media screen and (max-width: $tablet) {
      max-height: 40px;
    }
  }
}

.menu-footer {
  margin-bottom: rem(20);

  &__list {
    display: flex;
    align-items: center;
    column-gap: rem(50);
  }

  &__link {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #fff;
    cursor: pointer;
    transition: all 0.5s ease 0s;

    @media (any-hover: hover) {
      &:hover {
        color: #d0b9ee;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.sort-lang {
  &__sort {
    min-width: 50px;
    border-radius: 67px;
    background: rgba(255, 255, 255, 0.92);
    padding: 20px;
    backdrop-filter: blur(7.5px);
    position: relative;
    height: 100%;
    transition: all 0.45s;
    align-self: baseline;

    &.active {
      height: calc(100% + 20px);

      .sort-lang__sort-content {
        max-height: 260px;
        opacity: 1;
        visibility: visible;
        z-index: 3;
        overflow-y: auto;
        width: 100%;

        li span {
          border-radius: 0px 0px 3px 3px;
          border: 1px solid #f4f3f3;
        }
      }

      .sort-lang__sort-label:after {
        transform: rotate(180deg);
      }
    }

    @media screen and (max-width: $tablet) {
      padding: 7px 13px;
      align-self: unset;
      border-radius: 10px;
      background: rgba(246, 245, 249, 0.92);
      height: 38px;
      backdrop-filter: blur(7.5px);

      &.active {
        height: 55px;
      }
    }

    &:first-child {
      min-width: 50px;
    }

    &:first-child .sort-lang__sort-content {
      min-width: 214px;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    input:checked + .sort-lang__sort-label span {
      border-radius: 0px 0px 3px 3px;
      border: 1px solid #f4f3f3;
    }

    input:checked ~ .sort-lang__sort-content {
      max-height: 260px;
      opacity: 1;
      visibility: visible;
      z-index: 3;
      overflow-y: auto;
      width: 100%;

      li span {
        border-radius: 0px 0px 3px 3px;
        border: 1px solid #f4f3f3;
      }
    }
  }

  &__sort-label {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #141f4b;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 140%;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: url('./assets/img/chevron-down.svg') no-repeat;
      text-align: center;
      transition: all 0.35s;
    }
  }

  &__sort-content {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    max-height: 260px;
    min-width: 44px;
    width: 100%;
    transition: all 0.45s;
    color: #262626;
    z-index: 3;

    li {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
}
</style>
