import { createStore } from 'vuex'
// import axios from 'axios'

export default createStore({
  state: {
    activeLanguage: 'ru',
    isNavOpen: false,
    user: {}
  },
  getters: {
    activeLanguage(state) {
      return state.activeLanguage;
    },
    NAV(state) {
      return state.isNavOpen;
    },
  },
  mutations: {
    toggleNav(state) {
      state.isNavOpen = !state.isNavOpen
    },
    SET_LANGUAGE(state, language) {
      state.activeLanguage = language;
    },
    send_success(state, user) {
      state.status = 'success'
      state.user = user
    },
  },
  actions: {
    setLanguage(context, language) {
      context.commit('SET_LANGUAGE', language);
    },
    sendEmail() {
      return new Promise(() => {
        // axios({ url: 'https://bibig.ru/auth/getMail/', data: user, method: 'POST', headers: { 'content-type': 'application/json' } })
        //   .then(resp => {
        //     console.log(resp)
        //     commit('send_success', user)
        //     resolve(resp)
        //   })
        //   .catch(err => {
        //     reject(err)
        //   })
        console.log("mail")
      })
    },
  },
  modules: {
  }
})
